//const authBase = "discountwasteapp"; //PROD
//const clientId = "487e0f19-2f32-4b3b-ac16-f1772baa988b"; //PROD
const authBase = 'externalwasteapps'; //QA
const clientId = 'daef0902-fd32-4839-a0fa-887ccfb5c4d8'; //QA
const b2cPolicies = {
  names: {
    signUpSignIn: "B2C_1_Auth-SignInSignUp",
    forgotPassword: "B2C_1_B2C-Auth-Reset",
    editProfile: "",
  },
  authorities: {
    signUpSignIn: {
      authority: `https://${authBase}.b2clogin.com/${authBase}.onmicrosoft.com/B2C_1_Auth-SignInSignUp`,
    },
    signUpSignUp: {
      authority: `https://${authBase}.b2clogin.com/${authBase}.onmicrosoft.com/B2C_1_B2C-Auth-SignIn`,
    },
    forgotPassword: {
      authority: `https://${authBase}.b2clogin.com/${authBase}.onmicrosoft.com/B2C_1_B2C-Auth-Reset`,
    },
  },
  authorityDomain: `${authBase}.b2clogin.com`,
};

export const msalConfig = {
  auth: {
    clientId: clientId, //"7aec2f377-de6d-4c58-a6b8-badc43c52574", //"6105fa54-45df-4dab-a33d-ebed7d15bccf", //"2fdd06f3-7b34-49a3-a78b-0cf1dd87878e", // Replace with your AppID/ClientID obtained from Azure Portal.
    authority: b2cPolicies.authorities.signUpSignIn.authority, // Choose sign-up/sign-in user-flow as your default.
    knownAuthorities: [b2cPolicies.authorityDomain], // You must identify your tenant's domain as a known authority.

    //redirectUri: "http://localhost:8080/sign-in",  // TEST
    redirectUri: "https://customer.discountwaste.com/sign-in", //(Production)
    //redirectUri: "https://payment.wasteapplications.com/sign-in", // (Test) You must register this URI on Azure Portal/App Registration. Defaults to "window.location.href".
    //postLogoutRedirectUri: "http://localhost:55460/signout", // (Local) Simply remove this line if you would like navigate to index page after logout.
    navigateToLoginRequestUrl: false, // If "true", will navigate back to the original request location before processing the auth code response.
    //allowPublicClient: null
  },
  cache: {
    cacheLocation: "sessionStorage", // Configures cache location. "sessionStorage" is more secure, but "sessionStorage" gives you SSO.
    storeAuthStateInCookie: false, // If you wish to store cache items in cookies as well as browser cache, set this to "true".
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        // switch (level) {
        // }
      },
    },
  },
};

export const loginRequest = {
  scopes: [],
};

export default { msalConfig, loginRequest };
